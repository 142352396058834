<template>
    <div class="mint_container">
        <!-- Wallet connectd -->
        <div v-if="isConnect" class="wallet-connection"  >
            <div class="token-wrap">
                <div class="cfx-balance">{{accountCFXBalance}} CFX</div>
                <div class="neko-balance">{{accountNekoBalance}} NEKO</div>
                <div class="meow-balance">{{accountNFTBalance}}</div>
                <div  style="float:left;font-size:0.6em; color:#666;"> Conflux Network : {{accAddressStorten}} </div>
            </div>
        </div>


        <!-- Wallet not connected -->
        <div v-if="!isConnect" class="wallet-connection"  >
            <div class="token-wrap">
                <div class="cfx-balance"> Conflux networks</div>
                <el-button 
                    style="font-size:1.2em; margin-top:-5px; background-color:#ffe000; color:#ff3600; border:1px solid #ff3600;" 
                    @click="connectButton()"> Connect wallet
                </el-button>
            </div>
        </div>



        <h1>Collections </h1> 

        <div style="background:none; margin:50px auto;">

            <!-- Asset Summery : NFT and Coins 
            <div class="list">
                <el-card class="Asset-Summery" style="width: 45%; height:230px; color:white;">
                    <div> Guardian owned <br><span style="text-align:center; font-size:5em; ">{{ this.totalGuardian}} </span></div>
                </el-card>
                <el-card class="Asset-Summery" style="width: 45% ;height:230px;">
                    <el-table :data="coinBalance" stripe empty-text="loading...">
                        <el-table-column prop="label" label="Coin"> </el-table-column>
                        <el-table-column prop="balance" label="Balance"> </el-table-column>
                    </el-table>
                </el-card>
                <div style="clear:both"></div>
            </div>
            -->

            <ul class="list">
                <li class="item"  v-for="(item) in NFTs" :key="item.id" >

                    <div class="item-wrap">
                        <div class="el-card-name">
                            <div> 
                                <div v-if="item.name"> {{item.name}} </div>
                                <div v-if="!item.name">Maneki 🐱</div>
                                <span style="color:#0375fc;">Guardian 招き猫 <span style="font-weight:bold">#{{item.id}}</span></span>
                            </div>
                        </div>
                        <router-link :to="'/token/' + item.id +'/0x0/'">
                            <el-card :body-style="{ padding: '0', }">
                                <img :src="item.image" class="image">
                            </el-card>
                        </router-link>
                        <div> Maneki-Power : {{item.power}} </div>
                        <div> Gene Pool : {{item.gamma}} </div>
                    </div>

                </li>

                <div style="clear:both;"></div>
            </ul>
        </div>

        <!-- Send as gift -->
        <el-dialog title="Send as gift" :visible.sync="dialog.sendGift">
            <el-form :model="giftForm">
                <el-form-item label="NFT" :label-width="formLabelWidth">
                <el-select v-model="giftForm.tokenID" placeholder="Select NFT">
                    <el-option :key="'neko-'+giftForm.tokenID" :label="'招き猫 #'+giftForm.tokenID" :value="giftForm.tokenID"></el-option>
                </el-select>
                </el-form-item>

                <el-form-item label="Receiver" :label-width="formLabelWidth">
                <el-input v-model="giftForm.receiverAddr" placeholder="(BSC) Wallet Address"></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialog.sendGift = false">Cancel</el-button>
                <el-button type="primary" @click="approveSendGift()"> Approve </el-button>
            </div>
        </el-dialog>

    
        <!-- List to exchange -->   
        <el-dialog title="List this NFT to exchange" :visible.sync="dialog.approveToExchange">
            <el-form :model="approvalForm">
                <el-form-item label="NFT" :label-width="formLabelWidth">
                <el-select v-model="approvalForm.tokenID" placeholder="Select NFT">
                    <el-option :key="'neko-'+approvalForm.tokenID" :label="'招き猫 #'+approvalForm.tokenID" :value="approvalForm.tokenID"></el-option>
                </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialog.approveToExchange = false">Cancel</el-button>
                <el-button type="primary" @click="approveTransfer()"> Approve </el-button>
            </div>
        </el-dialog>


        <!-- Create Auction -->
        <el-dialog title="Make an offer" :visible.sync="dialog.offerForm">
            <el-form :model="offerForm">
                <el-form-item label="NFT" :label-width="formLabelWidth">
                <el-select v-model="offerForm.tokenID" placeholder="Select NFT">
                    <el-option :key="'neko-'+approvalForm.tokenID" :label="'招き猫 #'+approvalForm.tokenID" :value="approvalForm.tokenID"></el-option>
                </el-select>
                </el-form-item>

                <el-form-item label="Coin" :label-width="formLabelWidth">
                    <el-radio-group v-model="offerForm.currency" size="medium">
                    <el-radio border label="BNB" value="BNB" primary></el-radio>
                    <el-radio border label="NEKO" value="NEKO"></el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="Amout" :label-width="formLabelWidth">
                    <el-input v-model="offerForm.price"></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialog.offerForm = false">Cancel</el-button>
                <el-button type="primary" @click="makeAnOffer()"> Confirm </el-button>
            </div>
        </el-dialog>   


        <!-- Download wallet -->
        <el-dialog title="Download or connect wallet" :visible.sync="downloadWallet">

            <div class="dialog-input-wrap">


                <div class="dialog-content">
                    <div style="margin: 0 auto; color:white; font-size:1.6em; text-align:center; padding-top:50px; padding-bottom:5px; width:350px; ">
                        <span style="color:yellow; font-size:0.8em;">Maneki-MeowをConfluxウォレットで<br>安全にチェーンに保管する</span><br>
                        Store Maneki-Meow <br>safely on-chain with
                        <br>
                        <img  style="margin-top:30px; width:220px;" src="https://fluentwallet.com/assets/logo.b3425fdb.svg" />
                    </div>
                </div>

                <div slot="footer" class="dialog-footer">
                    <el-button style="font-size:1.2em; margin-top:30px; background-color:#ffe000; color:#ff3600; border:1px solid #ff3600; "  > 
                        <a href="https://chrome.google.com/webstore/detail/fluent/eokbbaidfgdndnljmffldfgjklpjkdoi" target="_blank">
                            Free downlaod 
                        </a>
                    </el-button>
                    <div style="margin-top:60px;"> 
                        New to Conflux? 
                        <a href="https://fluentwallet.com/" target="_blank"> Learn more about wallets</a>
                    </div> 
                </div>

            </div>
        </el-dialog>

      <!-- Install Fluent -->
        <el-dialog  :visible.sync="dialogInstall" :close-on-click-modal="false" >
            <div class="dialog-input-wrap">
                <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; padding-top:120px; padding-bottom:20px; width:350px; margin:0 auto; "> 
                        <span style="color:yellow; font-size:0.7em;"></span> Connect to a wallet
                    </div>
                    <div class="walletButton">
                    <a href="https://fluentwallet.com/" target="_blank" >
                        <div style="width:250px; height:35px; color:black; font-size:1.7em; font-weight:100; float:left; "> Install Fluent Wallet </div>
                        <img style="width:35px; float:left; " src="../../src/assets/images/fluent-logo.png" />
                    </a>
                    </div>
                    <div style="color:#666; font-size:1em; padding-top:80px; width:350px; margin:0 auto; "> 
                        New to Conflux? <a href="https://fluentwallet.com/" target="_blank" style="color:#666;"> Learn more about wallet </a>
                    </div>
                </div>
            </div>
        </el-dialog>

      <!-- Activate address Fluent -->
        <el-dialog  :visible.sync="dialogActivate" :close-on-click-modal="false" >
            <div class="dialog-input-wrap">
                <div class="dialog-content">
                    <div style="color:white; font-size:1.8em; padding-top:120px; padding-bottom:20px; width:350px; margin:0 auto; "> 
                        <span style="color:yellow; font-size:0.7em;"></span> Connect to a wallet
                    </div>
                    <div class="walletButton" @click="authorizedWallet()">
                        <div style="width:250px; height:35px; color:black; font-size:1.7em; font-weight:100; float:left; "> Fluent Wallet </div>
                        <img style="width:35px; float:left; " src="../../src/assets/images/fluent-logo.png" />
                    </div>
                    <div style="color:#666; font-size:1em; padding-top:80px; width:350px; margin:0 auto; "> 
                        New to Conflux? <a href="https://fluentwallet.com/" target="_blank" style="color:#666;"> Learn more about wallet </a>
                    </div>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>


import abi_collectible from '../web3/abi_collectible';
//import abi_exchange from '../web3/abi_exchange';
import abi_neko from '../web3/abi_neko';

//Conflux SDK
const { Conflux } = require('js-conflux-sdk');
const conflux = new Conflux({
  url: 'https://main.confluxrpc.com',
  networkId: 1029,
});

console.log(conflux.version);
const provider = window.conflux


const contract_collectibles =  conflux.Contract({abi:abi_collectible, address:'cfx:acedfgnc6tp0g8t2yy35gmr4pw4cjk7sgus4fmktnn'});
const contract_neko = conflux.Contract({abi:abi_neko, address:'cfx:acejgycpayveh1heb94c1yrdrd1y5t9mhpbmatdsx3'});

const path_Metadata = 'https://md.maneki.market/token/';


export default {
    data(){
        return{
        isConnect: false,
        accountCFXBalance : 0,
        accountNekoBalance : 0,
        accountNFTBalance : 0,
        accAddress : '0x0',
        accAddressStorten : '0x0',
        dialogInstall : false,
        dialogActivate : false,


            activeIndex: '1',
            contractInstance:null,
            account:null,
            isCollapse:false,
            tokenSupply:0,
            name:null,
            loading : true,
            
            display :{
                isConnect : false,
            },
            // NFT 
            totalGuardian :0,
            NFT : {
                owned:0,
                supply:0,
                balance:0
            },
            NFTs :[],
            search: '',
            contract :{
                collectibles:null,
                exchange :null
            },
            dialog :{
                approveToExchange : false,
                offerForm : false,
                sendGift : false,
            },
            approvalForm: {
                tokenID: ''
            },
            offerForm: {
                tokenID: '',
                price: '',
                currency:'',
                buyer:''
            },
            giftForm : {
                tokenID: '',
                receiverAddr:''
            },
            formLabelWidth: '120px',
            coinPrice :'',
            web3:null,
            downloadWallet: false,
        }
    },
    mounted() {

        this.initWallet();
 
        provider.on('accountsChanged', accounts => {
            this.accAddressStorten= accounts[0]
            this.initWallet()
            this.isConnect = true
            this.dialogActivate = false
            console.log('accountsChanged = ', accounts)
        })
 
        provider.on('chainChanged', chainId => {
            console.log('chainChanged,  = ', chainId)
            provider.request({method: 'cfx_netVersion'}).then(networkId => {
            console.log('networkId,  = ', networkId)
            })
        })
    },
    methods :{



        initWallet(){
            
            // Fluent Extention Installed?
            if (window?.conflux?.isFluent) {
                // Installed
                this.walletInitialized()
            } else {
                //Fluent Not install, Request to make installation
                console.log('Fluent is not connected, please try refresh this page or restart your browser.')
                // Appear dialog , LINK - request install Fluent 
                //this.dialogInstall = true
            }
        },
        connectButton(){
            
            // Fluent Extention Installed?
            if (window?.conflux?.isFluent) {
                // Installed, Request authorized with wallet
                //this.walletInitialized()
                this.dialogActivate = true

            } else {
                //Fluent Not install, Request to make installation
                console.log('Fluent is not connected, please try refresh this page or restart your browser.')
                // Appear dialog , LINK - request install Fluent 
                this.dialogInstall = true
            }
        },
        async authorizedWallet(){
            console.log('Requesting account')
            provider
            .request({
            method: 'cfx_requestAccounts',
            })
            .then(this.walletInitialized())
            .catch(error => console.error('error', error.message || error))
        },
        async walletInitialized() {
            console.log('Fluent Wallet Connected')

            provider.request({method: 'wallet_getFluentMetadata'}).then(({version}) => {
                console.log('Fluent version :',version);
            })

            const [chainId, networkId, alreadyAuthedAddresses] = await Promise.all([
            provider.request({method: 'cfx_chainId'}),
            provider.request({method: 'cfx_netVersion'}),
            provider.request({method: 'cfx_accounts'}),
            ])

            console.log('initialized')
            console.log('chainId :',  chainId)
            console.log('networkId: ', networkId)          
            
            if (!alreadyAuthedAddresses.length) {
                // Appear Dialog - Coneect to a wallet FLUENT
                console.log ('no address',alreadyAuthedAddresses )

            } else {
                console.log ('Wallet Address :',alreadyAuthedAddresses[0])
                this.accAddress = alreadyAuthedAddresses[0]
                this.accAddressStorten  = alreadyAuthedAddresses[0]
                this.isConnect = true
                this.fetchData()
            }            


        },

        fetchData(){
                this.getNekoTokenBalance();
                this.getCFXBalance();
                this.getNFTBalance();
                
                this.getNFTOwned ();
                
        },

        async getNekoTokenBalance (){      
            await contract_neko.balanceOf(this.accAddress).then((res) => {
                console.log('Balance $NEKO:', (res.toString()/1e18).toFixed(2));
                this.accountNekoBalance = (res.toString()/1e18).toFixed(2);
            }).catch((err) => {
                console.log(err, 'err');
            });
        },

        async getCFXBalance(){
                await conflux.getBalance(this.accAddress).then((res) => {
                console.log('$CFX :', (res.toString()/1e18).toFixed(2));
                this.accountCFXBalance = (res.toString()/1e18).toFixed(2);
            }).catch((err) => {
                console.log(err, 'err!!');
            });
        },

        async getNFTBalance (){
            const balance = await contract_collectibles.balanceOf(this.accAddress);
            
            this.accountNFTBalance = balance[0];
            console.log('Total Guardian Owned:',balance); 
        },

        async getNFTDetails(_id){
            if(_id > 0){
            await contract_collectibles.Nekos(_id).call().then((res) => {

                this.NFT.id           = _id;
                this.NFT.power        = res[0]; 
                this.NFT.DNA          = res[1];
                this.NFT.refCount     = parseInt(res[2]);
                this.NFT.gamma        = parseInt(res[3]);
                this.NFT.piggyBank    = parseInt(res[4].toString()/10**18).toLocaleString() ;
                this.NFT.lastPrice    = parseInt(res[5].toString()/10**18).toLocaleString() ;

                console.log('Blessing used: ', parseInt(res[2]));

                this.getGuardian(this.NFT.id)
                
            })    
            }

        },






       async updateData(){
            await window.conflux.request({method: "cfx_requestAccounts"});

            if (typeof window.conflux !== "undefined"){
                console.log('Conflux Fluent is installed 2!');

            try {

                const cfx = window["conflux"]
                const accounts = await cfx.request({method: "cfx_requestAccounts"})

                this.account = accounts[0]
                this.shortenAccount = accounts[0].substring(0, 6) + '...' + accounts[0].substring(44, 49)
                this.display.isConnect = true;

                console.log('Connect Accounts :', this.account);


                //this.checkWalletBalance();

                // Store Coins Balance 
                this.getUSDTTokenBalance();
                this.getNekoTokenBalance();
                this.getCFXBalance();
                this.getTotalGuardian();

                this.getNFTOwned ();



            } catch (error) {
                if (error.code === 4001) {
                    // EIP 1193 userRejectedRequest error
                    console.log("Please connect to Fluent Wallet.")
                } else {
                    console.error(error)
                }
            }
            }


        },
        async checkWalletBalance(){
                // NEKO Balance
                await contract_neko.balanceOf(this.account).then((res) => {
                    this.accountNekoBalance = parseInt(res.toString()/1e18);
                    console.log('Balance $NEKO:', (parseInt(res.toString()/1e18)));
                }).catch((err) => {
                    console.log(err, 'err');
                });

                // CFX Balance
                await conflux.getBalance(this.account).then((res) => {
                    this.accountCFXBalance = (res.toString()/1e18).toFixed(5);
                }).catch((err) => {
                    console.log(err, 'err');
                });

  

        },


      
        async getNFTOwned (){
      
            // retrive owned NFTs
            await contract_collectibles.ownedNekos().call({
                from: this.accAddress,
            }).then((res) => {
        
                console.log ('NFT :',res);

            for (let i = 0; i < res.length; i += 1) {
                // retrive NFT Details
                const axios = require('axios');
                axios.get(path_Metadata + res[i])
                .then((metadata) => {

                    var generation = parseInt(metadata.data.attributes[0].value);
                   
                    this.NFTs.push({
                        id: res[i][0],
                        power: metadata.data.attributes[4].value.slice(0,7),
                        DNA: metadata.data.art_dna,
                        url: metadata.data.image,
                        gen : generation,
                        
                        name : metadata.data.name,
                        description : metadata.data.description,
                        image : metadata.data.image,
                        gamma : metadata.data.gamma,
                    });

                }).catch((err) => {
                    console.log(err, 'err');
                    //no file fetched back 
                    this.NFTs.push({
                        id: res[i][0],
                        power: 0,
                        DNA: 0,
                        url: 'https://t2nft.maneki.market/blank_meow.gif',
                        gen : 0,
                        
                        name : '',
                        description : '',
                        image : 'https://t2nft.maneki.market/blank_meow.gif',
                        gamma : 0,
                    });
                });
            }



            this.loading = false;

            }).catch((err) => {
                console.log(err, 'err');
            });

        },
        wantToSell (_tokenID) {
            this.approvalForm.tokenID = _tokenID;
            this.dialog.approveToExchange = true;
        },
        sendGift (_tokenID) {
            this.giftForm.tokenID = _tokenID;
            this.dialog.sendGift = true;
        },
        async approveSendGift (){   
            // Approve :to send this NFT as a gift to this address
            console.log('sender', this.account);
            console.log('reciever', this.giftForm.receiverAddr);
            await contract_collectibles.sendAsGift(this.giftForm.receiverAddr, this.giftForm.tokenID).call({
            from: this.account,
            }).then((res) => {
                console.log('approve gift sending',res);
                this.dialog.sendGift = false;
            })

        },
        async approveTransfer (){
            // Approve : this NFT transfer to Exchange
            await contract_collectibles.approve(this.contract_marketplace , this.approvalForm.tokenID).call({
            from: this.account,
            }).then((res) => {
                console.log('approve ',res);
                
                this.offerForm.tokenID = this.approvalForm.tokenID;
                this.dialog.approveToExchange = false;
                this.dialog.offerForm = true;
            })
        },

        makeAnOffer (){
            // Offer : Set the price for sale
            if (this.offerForm.currency=="BNB"){
                this.offerForm.currency = true;
            } else {
                this.offerForm.currency = false;
            }

            this.contract.exchange.methods.offer(
                this.offerForm.tokenID,
                this.offerForm.price,
                this.offerForm.currency,
                ).send({
            from: this.account,
            }).then((res) => {
                console.log('deal ',res);
                this.dialog.offerForm = false;
            })

        }

    }
}
</script>

<style scoped>
h1{
    width: 25%;
    font-size: 2em;
    color:rgba(255, 255, 0, 0.7);
    margin:0 auto;
    font-weight: 100;
    margin-bottom: 30px;
    text-align: center;
}
.Asset-Summery {
    float: left;
    min-width: 350px;
    background:rgba(128, 255, 209, .2); 
    margin:15px 20px;
    padding: 5px 10px;
}
.list {
    top:50px;
    width: 1100px;
    padding:0;
    margin:0 auto;
}
.item {
    border: 1px solid black;
    width: 250px;
    height : 400px;
    float:left;
    margin:15px 5px;
    padding: 5px 5px;
    padding-bottom: 30px;
    background:rgba(0, 0, 0, .6); 
    border-radius: 10px;
    list-style: none;
}
.el-card-wish{
    background-image: url("../../src/assets/images/icon-wish.png");
    background-repeat: no-repeat;
    background-position: top left; 
    padding-top: 5px;
    margin-top:5px;
    padding-left:60px;
    color:white; 
    font-size:1.2em;
    min-height: 100px;
}
.el-card-name{
    background-image: url("../../src/assets/images/icon-meow.png");
    background-repeat: no-repeat;
    background-position: top left; 
    padding-left:60px;
    color:white; 
    font-size:1.2em;
    min-height: 70px;
}
.wallet-connection{
    width: 460px;
    text-align: right;
    color: #c6c6c6;
    float:right;
    margin-right:30px;
}

.dialog-footer {
    text-align: center;
    color: white;
}

.dialog-footer a{
    text-decoration: none;
    color:#ff3600;;
    padding:5px;
}
.dialog-input-wrap{
    width:500px; 
    height:420px;
    margin:0 auto; 
    padding-bottom:15px; 
    padding-top:20px; 
    padding-left:20px; 
    padding-right:20px; 
    border:1px solid #000;  
    background:rgba(0, 0, 99, 0.8);  
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.dialog-content{
    height:250px;
}

.token-wrap{
    width: 460px;
    height: 25px;
    padding:20px;
    margin-top:-20px;
    background: #3f3f3f;
    border-radius: 0 0 10px 10px;
    background:rgba(0, 0, 0, .4); 
    border:solid 1px #0086ff;
}
.cfx-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-cfx.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:140px;
    float:left;
    color:white;
}
.neko-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-neko.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:140px;
    color:#fcee21;
    float:left;
}
.meow-balance {
    text-align: left;
    background-image: url("../../src/assets/images/icon-meow.png");
    background-repeat: no-repeat;
    background-position: top left; 
    background-size : 23px 23px;
    padding:2px;
    padding-left:30px;
    padding-right:5px;
    min-height: 27px;
    width:60px;
    color:#fcee21;
    float:left;
}
</style>