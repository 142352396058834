import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/login.vue'
import Wallet from '../components/wallet.vue'
import Poll from '../components/poll.vue'
import Connect from '../components/connect.vue'


// meta web
import VueMeta from 'vue-meta'


Vue.use(VueMeta)
Vue.use(VueRouter)

const routes = [
  //{path: '/', redirect:'/marketGroup/550000/650000'},
  {path: '/', redirect:'/poll'},
  {path: '/login', component:Login },
  {path: '/poll', component:Poll,
    redirect :'/poll', 
    children : [
      {path: '/wallet', component:Wallet },
      {path: '/poll', component:Poll },
      {path: '/connect', component:Connect },
    ]
  }
  
]
const router = new VueRouter({
  routes
})


export default router