<template>
    <!-- 
       HearUS. world
    -->
    <div class="mint_container">       

        <!-- Wallet panel -->
        <div v-if="idWalletPanel" class="tab-general" style="min-height:50px;">
            <div style="width:100%; height:50px;">
                <img src="../../src/assets/images/HearUS_logo.svg" style="width:100px;float:left;" @click="closeAllTab(),isPollTab = true"/>
                <img src="../../src/assets/images/icon_twitter.png" style="width:30px;float:right;" @click="goToURL('https://twitter.com/HearUS_world')"/>
            </div>
            
            <div style="clear:both;"></div>
        </div>

       


 
        <!-- Poll list panel -->
        <div v-if="isPollTab" class="content-tab  tab-general">
            <div style="background: #eee;padding:10px; margin-bottom:24px;">
                ⬇ Vote now! GAS FEE fully sponsored.
            </div>

            <div style="border:1px solid black;border-radius: 10px; margin-bottom:50px;text-align: center; " @click="isPollTab = false, isNetworks = true">
                <img src="../../src/assets/images/unreveal_A22HOLE-01.png"  style="width:28%; padding:1%; float:left;"/>
                <h2 style="width:64%;float:left; font-size:1.1em; padding:2%; padding-top:20px;">A22HOLE OF THE YEAR 2022
                </h2>
                <el-button style="width:135px; border-radius: 8px; background-color:#560bfc; color:white;">
                    Vote
                </el-button>
                <div style="clear:both;"></div>
            </div>
            <!--@click="goToURL( 'https://MYGE15.HearUs.World/' )"-->
            <div style="border:1px solid black; border-radius: 10px; margin-bottom:50px;text-align: center;" >
                <img src="../../src/assets/images/MYGE15.png"  style="width:28%; padding:1%; float:left;"/>
                <h2 style="width:64%;float:left; font-size:1.1em; padding:2%; padding-top:10px;">Who should be our next Prime Minister?
                </h2>
                <el-button style="width:135px; border-radius: 8px;  color:grey;">
                    Ended
                </el-button>
                <div style="clear:both;"></div>
            </div>

        </div>
  
        <!-- Network panel -->
        <div v-if="isNetworks" class="content-tab  tab-general">
            <div style="background: #eee;padding:10px; margin-bottom:24px;">
                ⬇ Vote now! GAS FEE fully sponsored.
            </div>

     

            <div style="border:1px solid black; padding:20px 0; text-align: center; border-radius: 10px;">
                <h1 style="color:#560bfc; font-size: 2.5em;"> Get started!</h1>
                <p style="font-size: 1.1em; margin-top:-25px; margin-bottom:-10px;"> Select your preferred network</p>
                <img src="../../src/assets/images/logo_confluxnetwork.png"  @click="goToURL( 'https://espaceA22HOLE.HearUs.World/' )" style="padding:5%; width:40%; float:left; cursor: pointer;"/>
             
                <img src="../../src/assets/images/logo_polygonnetwork.png"   @click="goToURL( 'https://A22HOLE.HearUs.World/' )" style="padding:5%; width:40%; float:left; cursor: pointer;"/>
                <div style="clear:both;"></div>

            <img src="../../src/assets/images/about_timeline.png"  style="width:100%; margin-bottom:20px; border-top:1px solid black;"/>
            </div>

        </div>

       
    </div>
    
</template>

<script>
import abi_collectible from '../web3/abi_collectible';
import abi_poll from '../web3/abi_poll';
import abi_payment from '../web3/abi_payment';


import getWeb3 from '../web3/web3';

/**
const target_chain = '0x13881';
const target_chainName = 'Mumbai';
const target_rpcUrls = 'https://matic-mumbai.chainstacklabs.com';
const target_name = 'Mumbai';
const target_symbol = 'MATIC';
const target_decimals = 18;
const target_blockExplorerUrls = 'https://mumbai.polygonscan.com';



const contract_collectibles = '0x6e6048e8a65C38FeB25Af7963DB848F5A9Bb4263';
const contract_poll = '0x918FbaeC30753A2827654e206b10779CFD0f3f1b';
const contract_payment = '0x32844F2478ca8Cc0DE5aE1c97069CB25f1642121';
const authKey = '0x000000000000000000000000697cb3a91d22f4cb39aeea7eb4a410fe090bbe06';

const scanPollURL = 'https://mumbai.polygonscan.com/address/';
const scanNFTURL  = 'https://mumbai.polygonscan.com/token/';
const scanTxURL   = 'https://mumbai.polygonscan.com/tx/';
*/




const target_chain = '0x89';
const target_chainName = 'Polygon';
const target_rpcUrls = 'https://polygon-rpc.com';
const target_name = 'Polygon';
const target_symbol = 'MATIC';
const target_decimals = 18;
const target_blockExplorerUrls = 'https://polygonscan.com';



const contract_collectibles = '0xed693ccaBEfA2CDa1B71F4612a915DbcFB7da497';
const contract_poll = '0x5fdc1299392855bA9D820D8230887EED2f51241d';
const contract_payment = '0xbc46726BFC6Bed595B9e59d253527B740CB7F0fD';
const authKey                   = '0x000000000000000000000000697cb3a91d22f4cb39aeea7eb4a410fe333bbe06';

const scanPollURL = 'https://polygonscan.com/address/';
const scanNFTURL  = 'https://polygonscan.com/token/';
const scanTxURL   = 'https://polygonscan.com/tx/';




// IPFS setting

// Import the NFTStorage class and File constructor from the 'nft.storage' package
import { NFTStorage, File } from 'nft.storage'

// The 'mime' npm package helps us set the correct file type on our File objects
import mime from 'mime'

// The 'fs' builtin module on Node.js provides access to the file system
import fs from 'fs'

// The 'path' module provides helpers for manipulating filesystem paths
import path from 'path'

// Paste your NFT.Storage API key into the quotes:
const NFT_STORAGE_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweEM4QjQ2NzQ3YTcxQzNDZjUzOTE3RTE3ZWVhQWRmMTVGMzU1RjExOWUiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY0MjQ2NTUxNjY3MywibmFtZSI6Im1lb3cifQ.ecyM6rhdmtiZhgxLRZNLaOVRIcAFBFASY3OJTieRM9M'

// -- end IPFS

//const provider = window.ethereum


export default {
  name: 'App',
  data() {
    return {

        contract :{
            NFTAddress : contract_collectibles,
            PollAddress: contract_poll,
            PaymentAddress : contract_payment,
            collectibles:null,
            poll:null,
            scanPoll: scanPollURL + contract_poll,
            scanNFT: scanNFTURL + contract_collectibles
        },
        price : {
            matic : 250,
            zeros : '000000000'
        },
        pollOptions :[
            { ID:0, image:'https://a22hole.hearus.world/assets/C_0.png'},
            { ID:1, image:'https://a22hole.hearus.world/assets/C_1.png'},
            { ID:2, image:'https://a22hole.hearus.world/assets/C_2.png'},
            { ID:3, image:'https://a22hole.hearus.world/assets/C_3.png'},
            { ID:4, image:'https://a22hole.hearus.world/assets/C_4.png'},
            { ID:5, image:'https://a22hole.hearus.world/assets/C_5.png'},
            { ID:6, image:'https://a22hole.hearus.world/assets/C_6.png'},
            { ID:7, image:'https://a22hole.hearus.world/assets/C_7.png'},
            { ID:8, image:'https://a22hole.hearus.world/assets/C_8.png'},
            { ID:9, image:'https://a22hole.hearus.world/assets/C_9.png'},
        ],
        calon: [{

            ID      : null,
            name    : null,
            image   : null,
            party   : null,
            seat    : null,
            twitter : null,
            wiki    : null,
            vote    : null,
            support : null,
            percentage :  0

        }],
        optionSelected: {
            ID:'',
            Title:'',
            Desc:''
        },
        votedCalon : {
            wallet: '',
            id : null,
            name:'',
            image:'',
            party:'',
            seat:'',
            twitter:'',
            wiki:'',
            HearUS:''
        },


        viewCalonIndex : 0,
        voteOption: 0,
        form :{
            HearUS :'',
            unit:1
        },

        accountAddress: null,
        isConnect: false,
        idWalletPanel: true,
        isMetamask: false,
        isChain : false,
        chainId : 0,
        chainName : 'Polygon',
        walletBalance : '',
        latestBlock : 0,
        topVote : 0,
        totalSubmission : 0,
        calonCount : 0,
        donation :0,
        NFTs :[],
        txHash :'',
        txHashURL :'',

        returnMetadata:'',
        imageInIPFS:'',
        
        isNetworks : false,
        isAboutTab : false,
        isPartyTab     : false,
        isPollTab   : true,
        isStatTab   : false,
        isCalonTab  : false,
        isHearUSTab : false,
        isMyVote    : false,
        isVoteAndMintTab : false,
        isNFTTab    : false,
        isVoteSession : true,
        isPopSupport : true,
        isPopJoin : false,
        isPopMint : false,
        isTxLoading : false,
        isTxFail : false,
        isVoteTxLoading : false,
        isVoteTxFail : false,



        isProcessCompile : false,
        isSendBlockchain : false,
        isTransactionEnd : false,
        isProcessVoteComplete : false,
        isPopMintSuccess : false,

        dialogVisible: false,

        myNFT : {
            id : '',
            option : '',
            image : ''
        },

        

        myVote : {
            calonName : '',
            voteOption    : 100,
            support : null,
            HearUs : null
        },




    };
  },
  created() {
  },
  
  mounted() {

    window.ethereum.on('accountsChanged', function (accounts) {
        // Time to reload your interface with accounts[0]!
        this.accountAddress = accounts[0];
        console.log('change account :', this.accountAddress);
    })

    window.ethereum.on('chainChanged', function (res) {
        // Time to reload your interface with the new networkId
        this.chainId = res;
        if (target_chain == res){
            this.isChain = true;
            console.log('is eSpace');
            console.log('correct network ', res);
        } else {
            this.isChain = false;
            this.isConnect = false;
            this.isMetamask = false;
            console.log('wrong network ', res);
        }
        console.log('isChain :', this.isChain);
        location.reload();     
    })

    getWeb3().then((res) => {
        this.web3 = res;

        this.contract.collectibles  = new this.web3.eth.Contract(abi_collectible, contract_collectibles);
        this.contract.poll          = new this.web3.eth.Contract(abi_poll, contract_poll);
        this.contract.payment       = new this.web3.eth.Contract(abi_payment, contract_payment);
        this.isMetamaskInstalled();
        this.isConnectNetwork();
        this.getTotalSubmission();
        

    }).catch((err) => {
        console.log(err, 'err!!');
    });
        

  },
  methods:{ 

    closeAllTab(){
        this.isNetworks = false
        this.isAboutTab = false
        this.isPartyTab = false
        this.isPollTab = false
        this.isStatTab = false
        this.isCalonTab = false
        this.isMyVote = false
        this.isHearUSTab = false
        this.isVoteAndMintTab = false
        this.isNFTTab = false
    },
    async isConnectNetwork(){
        if (window.ethereum) {
            const currentChainId = await window.ethereum.request({
            method: 'eth_chainId',
            });

            // return true if network id is the same
            if (currentChainId == target_chain){ 
                this.isChain =true;
                this.chainId = currentChainId;
                this.chainName = target_chainName;                 
                this.connectWallet();
                return true;
            }
            return false;
        }

    },
    async switchNetwork(){
        try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: target_chain }],
        });
        } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902 || switchError.code === -32603) {
            try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {   
                    chainId: target_chain,
                    chainName: target_chainName,
                    rpcUrls: [target_rpcUrls],
                    nativeCurrency: {
                        name: target_name,
                        symbol: target_symbol,
                        decimals: target_decimals
                    },
                    blockExplorerUrls: [target_blockExplorerUrls]
                    },
                ],

            });

            } catch (addError) {
            // handle "add" error
            }
        }
        // handle other "switch" errors
        }
    },
    async isMetamaskInstalled (){
        if (typeof window.ethereum !== 'undefined') {
        console.log('MetaMask is installed!');

        this.isMetamask = true;

        } else {

        console.log('MetaMask not installed!');
            // do something 
        }
    },
    async connectWallet () {

        if(this.chainId != target_chain){
            this.switchNetwork();
        } else {

            await window.ethereum.request({ method: "eth_requestAccounts", }).then((accounts) => {
                [this.accountAddress] = accounts;
                    if(this.accountAddress!=null){
                        this.isConnect = true;
                        this.dialogVisible = false;
                        this.walletShortAddress = this.accountAddress.substring(0, 6) + '...' + this.accountAddress.substring(38, 42);

                        this.web3.eth.getBalance(this.accountAddress).then((res) => {
                            this.walletBalance = (res.toString()/1e18).toFixed(2);
                        });

                        this.fetchData()
                    } else {
                        //request login metamask 
                        this.idWalletPanel = true;
                    }
            })
        }

    },


    fetchData(){   
        this.getLatestBlock()
        this.getIsWalletVoted()
        this.getTotalNFTSupply()
        this.getCalonList()
        this.getNFTsDATA()
    },  
    goToURL(_url){
        //window.location.href = _url;

        window.open(_url, '_self');
    },
    getLatestBlock(){

        this.web3.eth.getBlockNumber().then((res) => {
            this.latestBlock = res
            console.log('latest block , ', res)
        }).catch((err) => {
            console.log(err, 'err');
        });

    },

  
    async getNFTsDATA (){
        // get total NFT 
        await this.contract.collectibles.methods.totalSupply().call().then((res) => {
            this.totalNFT = res
            console.log('total NFT :',res); 

            for(let i=res-1; i>=res-25 ; i--){
                this.getParticularNFTdetails(i)
            }

        }).catch((err) => {
            console.log(err, 'err');
        });
    },

    async getParticularNFTdetails (_id){
        await this.contract.collectibles.methods.tokenURI(_id).call().then((res) => {
            const axios = require('axios');
            axios.get(res).then((metadata) => {
                if(metadata.data.image != 'https://lob.ipfs.nftstorage.link/blob'){
                    this.NFTs.push({
                        id : metadata.data.DNA,
                        option : metadata.data.name,
                        image : metadata.data.image,
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        }).catch((err) => {
            console.log(err, 'err');
        });
    },
    async getTotalSubmission (){

        await this.contract.poll.methods.getTotalVoters().call().then((res) => {

            console.log('total voters :',res); 
            this.totalSubmission = res

        }).catch((err) => {
            console.log(err, 'err');
        });

    },


    checkNetworkID(){


        this.web3.eth.getId().then((res) => {
            console.log('network id: ', res);
            //console.log('async accountNekoBalance :', res);
        }).catch((err) => {
            console.log(err, 'err');
        });
    },


    // vote with GAS SPONSOR
    async VoteByAuthkey(_voteOption){


        this.isVoteTxLoading = true

        // add private key to local wallet 
        const PRIVATE_KEY = '794447c0e213931578e461447680da920431b7a8d2297f3d01a20279196a50fe';

        this.web3.eth.accounts.wallet.add(PRIVATE_KEY)
        const sponsorWallet = this.web3.eth.accounts.wallet[0].address
        const transaction = this.contract.poll.methods.VoteByAuthkey(authKey, _voteOption, this.accountAddress)

        const _gasPrice = await this.web3.eth.getGasPrice()
        const _maxFeePerGas = (parseInt(_gasPrice) + 20000000000).toString()
        const _gasLimit=  await transaction.estimateGas({from: sponsorWallet})

        console.log('sponsor account : ', sponsorWallet);
        console.log('this account : ', this.accountAddress);
        console.log('gasPrice : ', _gasPrice);

        // _authKey, _ edOption, _wallet ,_HearUS
        this.contract.poll.methods.VoteByAuthkey(authKey, _voteOption, this.accountAddress).send({
           
            maxPriorityFeePerGas: _gasPrice,
            maxFeePerGas : _maxFeePerGas,
            from: sponsorWallet,
            gas: _gasLimit

        }).then((receipt) => {

            //this.isVoteSession = false
            //this.isVoteTxLoading = false

            //this.isSendBlockchain = false
            //this.isProcessVoteComplete = true
            
            console.log('return :', receipt)
            console.log('Tx hash :', receipt['transactionHash'])
            
            this.txHash = receipt['transactionHash']
            this.txHashURL = scanTxURL + receipt['transactionHash']

            //this.fetchData()
            this.isCalonTab = false
            this.isStatTab = true

        }).catch((err) => {

            this.isVoteTxLoading = false
            this.isVoteTxFail = true

            console.log(err, 'err');
        });


    },

    // Mint NFT by Sponsor
    async MintByBuyer(){
        this.isPopMint = false
        this.isTxLoading = true
        
        var _gasPrice = await this.web3.eth.getGasPrice()
        var _maxFeePerGas = (parseInt(_gasPrice) + 20000000000).toString()
        console.log('gasPrice : ', _gasPrice);
        console.log('maxFeePerGas : ', _maxFeePerGas);

        await this.contract.payment.methods.PayByCoin( this.form.unit).send({
            maxPriorityFeePerGas: _gasPrice,
            maxFeePerGas : _maxFeePerGas,
            from: this.accountAddress,
            value: (this.price.matic * this.form.unit).toString() + this.price.zeros,

        }).then((receipt) => {

            console.log('return Minting :', receipt)
            //this.isSendBlockchain = false
            //this.isTransactionEnd = true
            //this.fetchData()
            this.txHash = receipt['transactionHash']
            this.txHashURL = scanTxURL + receipt['transactionHash']

            this.isTxLoading = false
            this.isPopMintSuccess = true

        }).catch((err) => {
            console.log(err, 'err');
            this.isTxFail = true
        });
    },
 
    async submitSupport(_donation, _voteOption){

        this.donation = _donation

        this.voteOption = _voteOption
        // Generate metadata , image and store into IPFS
        this.initForPainting(_voteOption)

    },
    // Vote + Mint NFT (FREE)
    async mintingVoteByMintNFTAuthkey(_tokenURI){
        this.isProcessCompile = false
        this.isSendBlockchain = true

        // add private key to local wallet 
        const PRIVATE_KEY = 'a7cea53d8b1c1000de31619e84e21349f470c34a06a37cb995f844dc9d5a06e8';

        this.web3.eth.accounts.wallet.add(PRIVATE_KEY)
        const sponsorWallet = this.web3.eth.accounts.wallet[0].address
        const transaction = this.contract.poll.methods.voteByMintNFTAuthkey(authKey, _tokenURI, this.votedCalon.id , this.accountAddress, this.form.HearUS)

        const _gasPrice = await this.web3.eth.getGasPrice()
        const _maxFeePerGas = (parseInt(_gasPrice) + 20000000000).toString()
        const _gasLimit=  await transaction.estimateGas({from: sponsorWallet})

        console.log('sponsor account : ', sponsorWallet);
        console.log('this account : ', this.accountAddress);
        console.log('gasPrice : ', _gasPrice);


        await this.contract.poll.methods.voteByMintNFTAuthkey(authKey,_tokenURI, this.votedCalon.id , this.accountAddress, this.form.HearUS).send({
            maxPriorityFeePerGas: _gasPrice,
            maxFeePerGas : _maxFeePerGas,
            from: sponsorWallet,
            gas: _gasLimit

        }).then((receipt) => {

            console.log('return :', receipt)
            this.isSendBlockchain = false
            this.isTransactionEnd = true
            this.fetchData()

        }).catch((err) => {
            console.log(err, 'err');
        });
    },

    // vote + Mint NFT (1 MATIC)
    async mintingVoteByMintNFT(_tokenURI){
        this.isProcessCompile = false
        this.isSendBlockchain = true


        var _gasPrice = await this.web3.eth.getGasPrice()
        var _maxFeePerGas = (parseInt(_gasPrice) + 20000000000).toString()
        console.log('gasPrice : ', _gasPrice);
        console.log('maxFeePerGas : ', _maxFeePerGas);

        await this.contract.poll.methods.voteByMintNFT( _tokenURI, this.voteOption, this.form.HearUS).send({
            maxPriorityFeePerGas: _gasPrice,
            maxFeePerGas : _maxFeePerGas,
            from: this.accountAddress,
            value: (this.donation).toString() + '000000000000000000',

        }).then((receipt) => {

            console.log('return :', receipt)
            this.isSendBlockchain = false
            this.isTransactionEnd = true
            this.fetchData()

        }).catch((err) => {
            console.log(err, 'err');
        });
    },

    // Rertrive Details 
    async getCalonList(){
        await this.contract.poll.methods.getTotalOptions().call().then((res) => {
            console.log('calon list ,', res)
            this.calon.pop()

            for( let i=0 ; i < res; i++)
            this.getCalon(i);

            // retrive user submission
            this.getMyVote(this.accountAddress)

            console.log('calon list, ', this.calon)
        }).catch((err) => {
            console.log(err, 'err');
        });
    },
    async getCalon(_colon){

        await this.contract.poll.methods.Options(_colon).call().then((res) => {
            

            if(this.topVote < parseInt(res[3])){
                this.topVote = parseInt(res[3])
            }

            this.calon.push({
            ID      : res[0],
            Title    : res[1],
            Desc   : res[2],
            image   : 'https://a22hole.hearus.world/assets/C_' + res[0] + '.png',
            vote    : res[3],
            percentage : 0
            })

            this.calonCount++
            if(this.calonCount==10){
                //this.sortOptionByID()
                this.sortCalonByVotes()
            }
            

        }).catch((err) => {
            console.log(err, 'err');
        });
    },
    sortOptionByID(){
        for(let i =0; i<this.calon.length ; i++){
            this.calon[i].percentage = this.calon[i].vote/this.topVote*100
            console.log('%  :', this.calon[i].percentage)
            
        }
        this.calon.sort(function(b, a){return b.ID - a.ID});

    },
    sortCalonByVotes(){
        console.log('top vote :', this.topVote)

        for(let i =0; i<this.calon.length ; i++){
            this.calon[i].percentage = this.calon[i].vote/this.topVote*100
            console.log('%  :', this.calon[i].percentage)      
        }
        this.calon.sort(function(a, b){return b.vote - a.vote});
    },
    optionToSelect(_Index){
        console.log('optionTo Select :', _Index)

        for ( let i=0; i<this.calon.length ; i++){
            
            if ( this.calon[i].ID == _Index){
             
                this.optionSelected.ID = this.calon[i].ID
                this.optionSelected.Image = this.calon[i].image
                this.optionSelected.Title = this.calon[i].Title
                this.optionSelected.Desc = this.calon[i].Desc
            }

        }

        this.closeAllTab()
        this.isCalonTab = true

    },
    viewCalon(_Index){
        this.closeAllTab()
        this.isCalonTab = true
        this.viewCalonIndex = _Index
    },

    async getIsWalletVoted(){
        await this.contract.collectibles.methods.name().call().then((res) => {
            console.log('NFT Name',res);
        }).catch((err) => {
            console.log(err, 'err');
        });
    },
    async getTotalNFTSupply(){
        await this.contract.collectibles.methods.totalSupply().call().then((res) => {
            console.log('Total NFT',res);
        }).catch((err) => {
            console.log(err, 'err');
        });
    },
    async getMyVote(_wallet){
            console.log('my survey in:', _wallet);
        await this.contract.poll.methods.VoterByAddress(_wallet).call().then((res) => {
            console.log('my survey :',res);

            this.myVote.wallet = res[0]
            this.myVote.voteOption = res[1]
            this.myVote.support = res[2]
            this.myVote.HearUs = res[3]

            // change vote option into text 
            for(let i=0; i< this.calon.length ; i++){

                console.log('calon id ', this.calon[i].ID)

                if(this.calon[i].ID == parseInt(res[1])){
                    this.myVote.calonName = this.calon[i].name
                }
            }

            console.log('my answer :', this.myVote);

            if(res[2]== true){
                this.getNFTbyWallet(_wallet)
            }

        }).catch((err) => {
            console.log(err, 'err');
        });

    },

    async getNFTbyWallet(_wallet){
        await this.contract.collectibles.methods.NFTbyWallet(_wallet).call().then((res) => {
            console.log('NFTs owned :',res);
            this.getTokenURI(res[0])
        }).catch((err) => {
            console.log(err, 'err');
        });
    },

    async getTokenURI(_NFTid){
        await this.contract.collectibles.methods.tokenURI(_NFTid).call().then((res) => {

            
            const axios = require('axios');

            axios.get(res).then((metadata) => {
                this.myNFT.tokenID      = _NFTid
                this.myNFT.DNA          = metadata.data.DNA
                this.myNFT.Wallet       = metadata.data.Wallet
                this.myNFT.code         = metadata.data.code
                this.myNFT.description  = metadata.data.description
                this.myNFT.image        = metadata.data.image
                this.myNFT.external_url = metadata.data.external_url
                this.myNFT.name         = metadata.data.name
                this.myNFT.Candidate    = metadata.data.attributes[0].value
                this.myNFT.Party        = metadata.data.attributes[1].value
                this.myNFT.Seat         = metadata.data.attributes[2].value
                this.myNFT.Twitter      = metadata.data.attributes[3].value
                this.myNFT.Wikipedia    = metadata.data.attributes[4].value
                this.myNFT.HearUS       = metadata.data.attributes[5].value
                this.myNFT.Created      = metadata.data.attributes[6].value

                    console.log('token URI :',this.myNFT);
            })
            .catch(function (error) {
                console.log(error);
            });



        }).catch((err) => {
            console.log(err, 'err');
        });
    },
    addTokenToWallet(){
        window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC721',
                options: {
                    address: contract_collectibles,
                    symbol: 'MYGE15',
                    name: 'Malaysia GE15',
                },
                },
            }).then((res) => {
            console.log ('Success, Neko Token added! ', res)
        }).catch(error => console.error('error', error.message || error))

    },
    
    voteAndSupport(_ID){
        let postvalue
        // get the calon detail as Metadata
        for(let i =0 ; i < this.calon.length ; i++){
            if(this.calon[i].ID == _ID){
                postvalue = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        id : this.calon[i].ID,
                        name:this.calon[i].name,
                        image:this.calon[i].image,
                        party:this.calon[i].party,
                        seat:this.calon[i].seat,
                        twitter:this.calon[i].twitter,
                        wiki:this.calon[i].wiki
                    })
                };          
            }
        }

        this.sendForPainting(postvalue)

    },

    initForPainting (_ID){

        // get the calon detail as Metadata
        for(let i =0 ; i < this.calon.length ; i++){
            if(this.calon[i].ID == _ID){
                this.votedCalon.wallet  = this.accountAddress
                this.votedCalon.id      = this.calon[i].ID,
                this.votedCalon.name    = this.calon[i].name,
                this.votedCalon.image   = this.calon[i].image,
                this.votedCalon.party   = this.calon[i].party,
                this.votedCalon.seat    = this.calon[i].seat,
                this.votedCalon.twitter = this.calon[i].twitter,
                this.votedCalon.wiki    = this.calon[i].wiki,
                this.votedCalon.HearUS  = this.form.HearUS
            }
        }
        this.sendForPainting();

    },
    // Painting NFT image
    async sendForPainting (){
            // generate Metadata and GIF
            // https://t2api.maneki.market/


            console.log('start painting :', this.votedCalon )

            await this.$http.post('/painter/', this.votedCalon).then((res) => {
                //response Meta 
                console.log('api data : ', res);

            // retrive painted image with name 
            const axios = require('axios');

            axios.get(res.data).then((metadata) => {
                console.log('metadata:',metadata);
                //console.log(metadata.data);
                this.returnMetadata = metadata;
                this.storeImageToIPFS();
            })
            .catch(function (error) {
                console.log(error);
            });

                
            }).catch((err) => {
                console.log(err, 'err! generate NFT images. Retry');
            });

    },
    async storeImageToIPFS (){
        console.log('start store image to ipfs')
        //this.dialog.wish = false;
        //this.dialog.painting = true;

        console.log(this.returnMetadata.data.image);
        console.log(this.returnMetadata.data.code + 'a');
        console.log(this.returnMetadata.data.description);

        // store Image to IPFS
        //storeNFT(imagePath, name, description) {
        await this.storeNFT(this.returnMetadata.data.image, this.returnMetadata.data.code + 'a', this.returnMetadata.data.description).then((result) => {
            console.log('storeNFT return : ', result)

            // construct ipfs url 
            const ipfsPath  = 'https://' + result.data.image.href.slice(7,66) + '.ipfs.nftstorage.link/blob'
            //const ipfsPath  = 'https://bafybeicaldgsodc6mk6hjo4jo4iyblmwbkccopmi3237skhmw4t4eikxfi.ipfs.nftstorage.link/blob'
            console.log('retrice NFT.storage : ', result.data.image.pathname)
            console.log('constructed url : ', ipfsPath)



            //change IPFS image url in Metadata
            this.returnMetadata.data.image = ipfsPath;

            console.log('preview new metadata : ', this.returnMetadata.data);
            
            //this.rewriteMetadata (this.previewCode, ipfsPath)
            this.storeMetadataToIPFS();

        })
    },

    async storeMetadataToIPFS () {
        const storage = new NFTStorage({ token: NFT_STORAGE_KEY})
        const content = new Blob([JSON.stringify(this.returnMetadata.data)])
        
        const cid = await storage.storeBlob(content)
        console.log({ cid })
        const status = await storage.status(cid)
        this.newTokenURI = 'https://' + status.cid + '.ipfs.nftstorage.link/';
        console.log('storeMetadataToIPFS', this.newTokenURI);
        
        if(this.donation == 0){
            //free
            this.mintingVoteByMintNFTAuthkey(this.newTokenURI);

        } else {
            this.mintingVoteByMintNFT(this.newTokenURI);
        }


    },

    async storeNFT(imagePath, name, description) {
        // load the file from disk
        const image = await this.getImageFromURL(imagePath)

        // create a new NFTStorage client using our API key
        const nftstorage = new NFTStorage({ token: NFT_STORAGE_KEY })

        // call client.store, passing in the image & metadata
        return nftstorage.store({
            image,
            name,
            description,
        })
    },
    async fileFromPath(filePath) {
        const content = await fs.promises.readFile(filePath)
        const type = mime.getType(filePath)
        return new File([content], path.basename(filePath), { type })
    },
    async getImageFromURL(imagePath) {
        const imageOriginUrl = imagePath
        const r = await fetch(imageOriginUrl)
        if (!r.ok) {
            throw new Error(`error fetching image: [${r.statusCode}]: ${r.status}`)
        }
        return r.blob()
    },   


    // utilities


  }
}
</script>

<style scoped>
.mint_container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -21px;
    right: -17px;
    overflow-y: scroll;
}


.tab-general{
    margin:0 auto;
    padding-top: 10px;
    width:478px;
}

.tab-start-empty{
    color:black;
    width: 478px;
    min-height: 480px;
    border: 1px solid black;
    border-radius: 20px;
}
.nft-field {
    width:100%;
    margin:0;
    border:1px solid grey;
    padding:5px;
    min-height:350px;
    padding-bottom:30px;
    margin-bottom:50px;
}
.nft-field li {
    clear:both;
    margin:0;
    padding:10px 5px;
    list-style: none;
}
.nft-field-1 {
    width:25%;
    float:left;
}
.nft-field-2{
    width:75%;
    float:left;
    word-wrap: break-word;
}

.overlay_popout, .overlay_vote_popout{
    text-align: center;
    position:relative; 
    top: -1350px; 
    width:95%; 
    margin:0 auto; 
    height:500px; 
    border:2px solid black; 
    border-radius: 25px; 
    background-color: white; 
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.overlay_vote_popout {
    top: -560px; 
}
@media only screen and (max-width: 478px)  {
.tab-general {
    width:468px;
}
}

@media only screen and (max-width: 428px)  {
.tab-general {
    width:418px;
}
}
@media only screen and (max-width: 390px)  {
.tab-general {
    width:390px;
}
}
@media only screen and (max-width: 384px)  {
.tab-general {
    width:384px;
}
}
@media only screen and (max-width: 375px)  {
.tab-general {
    width:375px;
}
}


@media only screen and (max-width: 360px)  {
.tab-general {
    padding-top: 10px;
    width:360px;
}
}
@media only screen and (max-width: 320px)  {
.tab-general {
    width:320px;
}
}
</style>